import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useAutoComplete, useGlobalItems } from '../../hooks';
import { UiContext } from '../../contexts';

import Routes from '../../components/Routes';
// import { SideBar } from 'cet-components-lib/dist/Content/Site/SideBar';
import { SideBar } from '../../components/SideBar';
import { Header } from 'cet-components-lib/dist/Content/Site/Header';
import { Footer } from 'cet-components-lib/dist/Content/Site/Footer';

import OldSiteMessage from '../../components/OldSiteMessage';
import SnackbarMessage from '../../components/SnackbarMessage';
import GradesEditor from '../../components/GradesEditor';
import VideoContainer from '../VideoContainer';
import SystemMessagesContainer from '../SystemMessagesContainer';
import MobileLandscapeView from '../../components/MobileLandscapeView';
import DropBox from '../../components/DropBox';
import { useCetEvents, useDeviceData, useSessionData } from 'cet-components-lib/dist/hooks';
import { setLoginData } from '../../state/ui';
import { Login } from 'cet-components-lib/dist/Content/Login';
import { MissingUserInfo } from 'cet-components-lib/dist/Content/MissingUserInfo';
import { useUserProfile, useGenderFormatMessage, useAnnualProfileUpdate, useSideBar } from '../../hooks';
import { getImageUrl } from '../../utils/resourceLoader';
import { CacheProvider } from '../../utils/cacheProvider';
import { avatarStorage, getObjectId, getObjectName, getQueryStringParams } from '../../utils/urls';
import SearchBoxPopUp from '../../components/SearchBox/SearchBoxPopUp';

import styles from './App.module.scss';

export default function App({ language, onUserReady }) {
  const sessionData = useSessionData();
  const isInTaskManagerIframe = window.location.pathname?.toLowerCase()?.includes('task-manager') || window.location.pathname?.toLowerCase()?.includes('environment-manager');
  const { isDeviceDataReady, isMobile, isMobileView, isDesktop, isAndroid, isIOS, isPortrait, isLandscape } = useDeviceData(true);
  useGlobalItems(isInTaskManagerIframe);
  const { updateUILanguage } = useUserProfile(true, isInTaskManagerIframe);
  const dispatch = useDispatch();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const intl = useIntl();
  const history = useHistory();
  const { push } = history;
  const [isInGradeEditFlow, setIsInGradeEditFlow] = useState(false);
  const [langs, setLangs] = useState(['he', 'ar']);
  const [{ snackbarMessage, oldSiteUrl, isSearchBoxOpenUiData, showLogin }, updateUiData] = useContext(UiContext);
  const { user, info, userId, lastUILanguage, schoolSectorId, lastSchool, loginData, currentItem, isTeacherCenter } = useSelector(({ user, ui, items }) => ({
    user,
    info: user.info,
    userId: user?.info?.userId,
    lastUILanguage: user?.info?.lastUILanguage,
    lastSchool: user.lastSchool,
    schoolSectorId: user?.lastSchool?.sectorId,
    loginData: ui.login,
    currentItem: items.currentItem,
    isTeacherCenter: Boolean(items?.filters?.teachersCenter)
  }));
  const { pathname, search } = useLocation();
  const { sendLearningEvent } = useCetEvents();
  const { shouldUserUpdateProfile } = useAnnualProfileUpdate(isInTaskManagerIframe);
  const [isInAnnualEditFlow, setIsInAnnualEditFlow] = useState(true);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const requestAutoComplete = useAutoComplete();
  const searchContainerRef = useRef();
  const autoCompleteText = useRef('');
  const isAutoCompleteEnabled = useRef();
  const direction = language === 'en' ? 'ltr' : 'rtl';
  const thumbnailId = info?.thumbnailId;
  const paramsMapper = getQueryStringParams();
  const sideBarItems = useSideBar();

  const handleSearchBoxBlur = event => {
    if (!searchContainerRef.current.contains(event.relatedTarget)) {
      setIsBlocking(false);
      if (!history.location.search.includes('allFields=')) {
        setIsSearchBoxOpen(false);
      }
    }

    setAutoCompleteOptions([]);
  };

  const handleToggleSearchBox = () => {
    setIsSearchBoxOpen(!isSearchBoxOpen);
    setAutoCompleteOptions([]);
  };

  const handleRequestAutoComplete = async (text, ageGrade, discipline) => {
    autoCompleteText.current = text;
    if (text?.length >= 3) {
      isAutoCompleteEnabled.current = true;
      const result = await requestAutoComplete(autoCompleteText.current, ageGrade, discipline);
      if ((isBlocking || isMobileView) && isAutoCompleteEnabled.current) {
        if (autoCompleteText.current === text) {
          setAutoCompleteOptions(result);
        }
      }
    } else {
      setAutoCompleteOptions([]);
    }
  };

  const handleSubmitSearch = (text, ageGrade, discipline, isExactSearch) => {
    isAutoCompleteEnabled.current = false;
    document.querySelector('#rootScroll').scrollTo(0, 0);
    history.push({
      pathname: `/${intl.locale}/search`,
      search: `?allFields=${text}${ageGrade && ageGrade.value !== 'all' ? '&ageGrades=' + ageGrade.value : ''}${
        discipline && discipline.value !== 'all' ? '&disciplines=' + discipline.value : ''
      }&exactSearch=${isExactSearch ? '1' : '0'}`.trim()
    });

    setAutoCompleteOptions([]);
    setIsBlocking(false);
  };

  const headerAttributes = useMemo(() => {
    return { 'data-scrolling-handler-element': 'header', 'data-scrolling-handler-data': isMobileView ? 'mobile' : 'pc' };
  }, [isMobileView]);

  const handleLanguageChange = useCallback(
    value => {
      let lastUILanguage = user?.lastUILanguage;

      const { pathname, search } = history.location;
      const pathParts = pathname.split('/');
      pathParts[1] = value;

      history.push({
        pathname: pathParts.join('/'),
        search
      });

      CacheProvider.set('updatingLanguage', 'true');

      if (user?.userId) {
        const userDataCacheKey = CacheProvider.generateKey('getTeacherData', { profileId: sessionData.userId } ?? '');
        CacheProvider.clearKeyValue(userDataCacheKey);
        if (lastUILanguage !== value) {
          updateUILanguage({ selectedLanguage: value, teacherId: user?.userId });
        }
      }
      window.location.reload();
    },
    [user?.lastUILanguage]
  );

  useEffect(() => {
    setIsBlocking(false);
    const isSearchPage = pathname.includes(`/${intl.locale}/search`);
    const isCatalogPage = pathname.includes(`/${intl.locale}/catalog`);
    const isShvilimPage = pathname.includes(`/${intl.locale}/teachers-center-shvilim`);
    const isSearchBoxOpen = !!paramsMapper.allFields && !isTeacherCenter && !shouldHideSearchButton && !(isSearchPage || isCatalogPage || isShvilimPage);
    setIsSearchBoxOpen(isSearchBoxOpen);
  }, [pathname, search, isTeacherCenter, paramsMapper?.allFields]);

  useEffect(() => {
    if (isSearchBoxOpenUiData) {
      setIsSearchBoxOpen(true);
    }
  }, [isSearchBoxOpenUiData]);

  function endGradeEditFlow() {
    setIsInGradeEditFlow(false);
    setIsInAnnualEditFlow(false);
  }

  const isReloadSentRef = useRef(false);
  const cetEventsRef = useRef({
    launched: '',
    exited: ''
  });

  const handleLoginClose = () => {
    updateUiData({ showLogin: false });
    dispatch(setLoginData(null));
  };

  const handleShowProfile = () => history.push(`/${intl.locale}/my-profile`);

  const handleLocaleRedirect = useCallback(() => {
    let updatingLanguage = CacheProvider.get('updatingLanguage');

    if (!updatingLanguage) {
      let locale = lastUILanguage ? lastUILanguage : schoolSectorId === 'arab' ? 'ar' : 'he';

      if (!pathname?.includes(`/${locale}`)) {
        const pathParts = pathname.split('/');
        pathParts[1] = locale;
        history.push({
          pathname: pathParts.join('/'),
          search
        });
        window.location.reload();
      }
    }
  }, [history, lastUILanguage, pathname, schoolSectorId, search]);

  useEffect(() => {
    if (userId?.info) {
      handleLocaleRedirect();
    }
  }, [handleLocaleRedirect, userId]);

  useEffect(() => {
    window.nairobi_api = {
      open_login: () => {
        updateUiData({ showLogin: true });
      },
      open_video: url => {
        updateUiData({ videoPlayerUrl: url });
      },
      open_search: (text = '', ageGradeId = null, disciplineId = null) => {
        updateUiData({
          isSearchBoxOpenUiData: true,
          searchBoxAgeGradesUiData: ageGradeId,
          searchBoxDisciplines: disciplineId,
          searchBoxText: text
        });
      }
    };
  }, [updateUiData]);

  useEffect(() => {
    if (lastSchool?.teacherAgeGrades?.length === 0) {
      setIsInGradeEditFlow(true);
    }
  }, [lastSchool?.teacherAgeGrades?.length]);

  useEffect(() => {
    if (window.interdeal?.setPosition) {
      window.interdeal.setPosition((intl.locale === 'he' || intl.locale === 'ar') && !isMobileView ? 'Right' : 'Left');
      window.interdeal.setLanguage(intl.locale);
    }
  }, [isMobileView, intl.locale]);

  useEffect(() => {
    const cetEventsRefCurrent = cetEventsRef.current;
    const currentPathName = window.location.pathname;
    if (!currentPathName.includes('/item/') && cetEventsRefCurrent.exited !== currentPathName.toLocaleLowerCase()) {
      // Send event if not Level 3
      let isReload = window.performance?.getEntriesByType && window.performance.getEntriesByType('navigation');
      isReload = isReload?.length > 0 && isReload[0].type && isReload[0].type === 'reload';

      if (isReload && !isReloadSentRef.current) {
        cetEventsRefCurrent.exited = currentPathName.toLocaleLowerCase();
        if (!window.contextReferrer) {
          //page refreshed
          window.contextReferrer = window.document.referrer || window.location.href;
        }
        window.currentHyperlink = window.location.href;
        sendLearningEvent({
          verbType: 'exited',
          objectId: getObjectId(),
          objectName: getObjectName(intl.locale)
        });
      }
    }

    isReloadSentRef.current = true;
  }, [intl.locale, sendLearningEvent]); //run once on init (checks for previously refreshed)

  useEffect(() => {
    const cetEventsRefCurrent = cetEventsRef.current;
    if (cetEventsRefCurrent.launched !== pathname.toLocaleLowerCase()) {
      const recommendationsCond = (info?.userId || window.contextReferrer?.includes('accounts')) && pathname.includes('/recommendations') && !search;
      if (!pathname.includes('/item/') && !recommendationsCond) {
        // Send event if not Level 3
        if (!window.contextReferrer) {
          window.contextReferrer = window.document.referrer || window.location.href;
        }
        window.currentHyperlink = window.location.href;
        cetEventsRefCurrent.launched = pathname.toLocaleLowerCase();
        sendLearningEvent({
          verbType: 'launched',
          objectId: getObjectId(),
          objectName: getObjectName(intl.locale)
        });
      }

      return () => {
        if (!pathname.includes('/item/') && !recommendationsCond && cetEventsRefCurrent.exited !== pathname.toLocaleLowerCase()) {
          // Send event if not Level 3
          sendLearningEvent({
            verbType: 'exited',
            objectId: getObjectId(),
            objectName: getObjectName(intl.locale)
          });

          window.contextReferrer = window.currentHyperlink;
          cetEventsRefCurrent.exited = pathname.toLocaleLowerCase();
        }
      };
    }
  }, [info?.userId, intl.locale, pathname, search, sendLearningEvent]);

  useEffect(() => {
    if (info && isDeviceDataReady) {
      onUserReady();
    }
  }, [info, isDeviceDataReady, onUserReady]);

  const [isForceProfileUpdate, setIsForceProfileUpdate] = useState(false);

  const closeForceUpdater = () => {
    endGradeEditFlow();
    push(pathname);
  };

  useEffect(() => {
    if (paramsMapper?.isForceProfileUpdate === 'true') {
      setIsForceProfileUpdate(true);
    } else {
      setIsForceProfileUpdate(false);
    }
  }, [paramsMapper?.isForceProfileUpdate]);

  useEffect(() => {
    if (isForceProfileUpdate) {
      if (user && info && !userId) {
        window.nairobi_api.open_login();
      }
    }
  }, [info, isForceProfileUpdate, user, userId]);

  const isUserCoordinator = info?.role?.toLowerCase() === 'coordinator';
  const isAdmin = info?.role?.toLowerCase() === 'admin';

  const hiddenSearchButtonPagesList = useMemo(
    () => [
      { pageName: 'Home', path: '', condition: true },
      { pageName: 'Catalog', path: '/catalog', condition: true },
      { pageName: 'Search', path: '/search', condition: !search.includes('teachersCenter') },
      { pageName: 'teachersCenterShvilim', path: '/teachers-center-shvilim', condition: true }
    ],
    [search]
  );

  const shouldHideSearchButton = hiddenSearchButtonPagesList.some(page => pathname === `/${intl.locale}${page.path}` && page.condition);
  const isMinimizedSidebar = isDesktop && userId && new RegExp(`^/${intl.locale}/teachers-center-shvilim(?:\\?|/item.*)?(?:\\?.*)?$`).test(pathname);

  if (!info || !isDeviceDataReady) {
    return <></>;
  }

  if (isInTaskManagerIframe) {
    return <Routes />;
  }

  if (info?.isValidProfile === false) {
    return <MissingUserInfo techSupportUrl={getGenderFormatMessage('missing_user_info_support_link')} />;
  }

  return (
    <div
      className={`${styles.app} ${isUserCoordinator || isMobileView ? styles.noSideBar : ''} ${isMinimizedSidebar ? styles.minimizedSidebar : ''}`}
      data-lang={language}
      data-direction={direction}
      data-is-mobile={isMobile}
      data-is-mobile-view={isMobileView}
      data-is-desktop={isDesktop}
      data-is-android={isAndroid}
      data-is-ios={isIOS}
      data-is-portrait={isPortrait}
      data-is-landscape={isLandscape}
    >
      <Header
        lang={intl.locale}
        langs={langs}
        isShowLogin={true}
        onShowProfileClick={handleShowProfile}
        headerAttributes={headerAttributes}
        onLanguageChange={handleLanguageChange}
        avatarUrl={thumbnailId ? `${avatarStorage}${thumbnailId}` : ''}
        avatarSize="medium"
        purchaseButtonGuestLabel={getGenderFormatMessage('purchase_button_guest_label')}
        purchaseButtonGuestHref={getGenderFormatMessage('purchase_button_guest_href')}
        purchaseButtonLabel={getGenderFormatMessage('purchase_button_label')}
        purchaseButtonHref={getGenderFormatMessage('purchase_button_href')}
        supportButtonLabel={getGenderFormatMessage('support_button_label')}
        supportButtonHref={getGenderFormatMessage('support_button_href')}
      >
        {!isUserCoordinator && !shouldHideSearchButton && isMobileView && (
          <div ref={searchContainerRef} className={styles.search}>
            <SearchBoxPopUp
              isOpen={isSearchBoxOpen}
              setIsSearchBoxOpen={setIsSearchBoxOpen}
              autoCompleteCategory="upperSearch"
              autoCompleteOptions={autoCompleteOptions}
              onFocus={() => {
                setIsBlocking(true);
              }}
              onBlur={handleSearchBoxBlur}
              onToggle={handleToggleSearchBox}
              onRequestAutoComplete={handleRequestAutoComplete}
              onSubmitSearch={handleSubmitSearch}
            />
          </div>
        )}
      </Header>
      {!isUserCoordinator && (
        <div className={`${styles.sidebarContainer} ${isMinimizedSidebar ? styles.minimizedSidebar : ''} `}>{(isDesktop || userId) && <SideBar items={sideBarItems} />}</div>
      )}
      <OldSiteMessage link={oldSiteUrl ? oldSiteUrl : ''} userName={info?.firstName ? info.firstName : ''} onClose={() => updateUiData({ oldSiteUrl: '' })} />
      <main className={`${styles.page} ${isMinimizedSidebar ? styles.minimizedSidebar : ''}`}>
        {language && (
          <>
            <SystemMessagesContainer />
            <Routes />
            {(loginData.isOpen || showLogin) && (
              <Login
                isWithPurchase={loginData.isPurchase}
                onClose={handleLoginClose}
                purchaseTitle={getGenderFormatMessage(`${currentItem?.type.toLowerCase()}_login_purchase`)}
                purchaseImageAlt={currentItem?.title}
                purchaseButtonUrl={loginData?.purchaseUrl}
                purchaseImage={currentItem?.type !== 'Lo' ? getImageUrl(currentItem?.thumbnailId, 220, 186) : null}
              />
            )}
            <SnackbarMessage
              timeout={5000}
              contentText={snackbarMessage ? snackbarMessage : ''}
              onTimeout={() => {
                updateUiData({ snackbarMessage: false });
              }}
            />
          </>
        )}
      </main>
      {info?.userId &&
        !isUserCoordinator &&
        (isInGradeEditFlow ? (
          <GradesEditor user={info} openSource={'noTeacherAgeGrades'} onClose={endGradeEditFlow} />
        ) : (
          !isAdmin &&
          (isForceProfileUpdate || (shouldUserUpdateProfile && isInAnnualEditFlow)) && <GradesEditor user={info} openSource={'annualUpdate'} onClose={closeForceUpdater} />
        ))}
      <Footer withSidebarMargin={true} />
      <DropBox />
      <MobileLandscapeView />
      <VideoContainer />
    </div>
  );
}
