import { useIntl } from 'react-intl';
import ExcelJS from 'exceljs';
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import { useGenderFormatMessage } from './useGenderFormatMessage';

export const useEducatorReport = () => {
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const intl = useIntl();

  const addRowsHeight = (sheet, startRow, endRow, height) => {
    for (let row = startRow; row <= endRow; row++) {
      sheet.getRow(row).height = height;
    }
  };

  const createCell = (sheet, cellAddress, value, font, fill, alignment, border) => {
    const cell = sheet.getCell(cellAddress);
    cell.value = value;
    cell.font = font;
    cell.fill = fill;
    cell.alignment = alignment;
    cell.border = border;
    return cell;
  };

  const generateExcelReport = async (data, minDate, maxDate, currentDate, password) => {
    for (const group of data) {
      const workbook = new ExcelJS.Workbook();

      // Home sheet
      const homeSheet = workbook.addWorksheet(getGenderFormatMessage('educatorReport_excel_homeSheetTabTitle'));
      homeSheet.views = [{ showGridLines: false, rightToLeft: true }];

      // Set column widths and row heights
      homeSheet.getColumn('B').width = 15;
      homeSheet.getColumn('C').width = 20;
      homeSheet.getColumn('E').width = 25;
      homeSheet.getColumn('F').width = 20;
      homeSheet.getColumn('H').width = 50;
      homeSheet.getRow(10).height = 35;

      // Fetch the logo image file
      const response = await fetch(require(intl.locale === 'he' ? '../components/EducatorReportModal/LogoOfekHE.png' : '../components/EducatorReportModal/LogoOfekAR.png'));
      const logoBlob = await response.blob();
      const logoArrayBuffer = await logoBlob.arrayBuffer();

      // Add the image to the workbook
      const imageId = workbook.addImage({
        buffer: logoArrayBuffer,
        extension: 'png'
      });

      // Insert the image into the cell with specified width and height
      homeSheet.addImage(imageId, {
        tl: { col: 1, row: 1 },
        ext: { width: 160, height: 60 }
      });

      // Title
      homeSheet.mergeCells('B6:F7');
      createCell(
        homeSheet,
        'B6',
        getGenderFormatMessage('educatorReportExcel_homeSheetTitle'),
        { size: 22, bold: true, color: { argb: '5750FA' } },
        { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2F2F2' } },
        { horizontal: 'right', vertical: 'middle', wrapText: true },
        {}
      );

      // Subtitle
      homeSheet.mergeCells('B8:F8');
      createCell(
        homeSheet,
        'B8',
        getGenderFormatMessage('educatorReportExcel_homeSheetSubtitle'),
        { size: 16, bold: true, color: { argb: '000000' } },
        { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2F2F2' } },
        { horizontal: 'right', vertical: 'middle', wrapText: true },
        {}
      );

      // Rectangle for report data
      homeSheet.mergeCells('B10:C10');
      createCell(
        homeSheet,
        'B10',
        getGenderFormatMessage('educatorReportExcel_homeSheet_reportDataTitle'),
        { size: 14, color: { argb: 'FFFFFF' } },
        { type: 'pattern', pattern: 'solid', fgColor: { argb: '00A4E2' } },
        { horizontal: 'right', vertical: 'middle', wrapText: true },
        {}
      );

      const reportDataList = [
        { label: `${getGenderFormatMessage('educatorReportExcel_homeSheet_reportDataCreatedDate')}`, value: `${currentDate}` },
        { label: '', value: '' },
        { label: `${getGenderFormatMessage('educatorReportExcel_homeSheet_reportDataFromDate')}`, value: `${minDate}` },
        { label: `${getGenderFormatMessage('educatorReportExcel_homeSheet_reportDataToDate')}`, value: `${maxDate}` },
        { label: '', value: '' },
        { label: `${getGenderFormatMessage('educatorReportExcel_homeSheet_reportDataGroupName')}`, value: `${group.groupName}` }
      ];

      const reportDataListStartRow = 11;
      const reportDataListEndRow = reportDataListStartRow + reportDataList.length - 1;

      reportDataList.forEach((item, index) => {
        const row = reportDataListStartRow + index;
        createCell(
          homeSheet,
          `B${row}`,
          item.label,
          { size: 12, color: { argb: '000000' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {
            top: { style: 'thin', color: { argb: 'C7C7C7' } },
            left: { style: 'thin', color: { argb: 'C7C7C7' } },
            bottom: { style: 'thin', color: { argb: 'C7C7C7' } },
            right: { style: 'thin', color: { argb: 'C7C7C7' } }
          }
        );
        createCell(
          homeSheet,
          `C${row}`,
          item.value,
          { size: 12, color: { argb: '000000' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {
            top: { style: 'thin', color: { argb: 'C7C7C7' } },
            left: { style: 'thin', color: { argb: 'C7C7C7' } },
            bottom: { style: 'thin', color: { argb: 'C7C7C7' } },
            right: { style: 'thin', color: { argb: 'C7C7C7' } }
          }
        );
      });

      addRowsHeight(homeSheet, reportDataListStartRow, reportDataListEndRow, 25);

      // Rectangle for the student list
      homeSheet.mergeCells('E10:F10');
      createCell(
        homeSheet,
        'E10',
        getGenderFormatMessage('educatorReportExcel_homeSheet_studentListTitle'),
        { size: 14, color: { argb: 'FFFFFF' } },
        { type: 'pattern', pattern: 'solid', fgColor: { argb: '00A4E2' } },
        { horizontal: 'right', vertical: 'middle', wrapText: true },
        {}
      );

      const studentListStartRow = 11;
      const studentListEndRow = studentListStartRow + group.students.length - 1;

      group.students.forEach((student, index) => {
        const row = studentListStartRow + index;
        const studentFullName = `${student.lastName} ${student.firstName}`;
        createCell(
          homeSheet,
          `E${row}`,
          { text: studentFullName, hyperlink: `#'${studentFullName}'!A1`, tooltip: studentFullName },
          { size: 12, bold: true, underline: true, color: { argb: '5750FA' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {
            top: { style: 'thin', color: { argb: 'C7C7C7' } },
            left: { style: 'thin', color: { argb: 'C7C7C7' } },
            bottom: { style: 'thin', color: { argb: 'C7C7C7' } },
            right: { style: 'thin', color: { argb: 'C7C7C7' } }
          }
        );
        createCell(
          homeSheet,
          `F${row}`,
          student.idNumber,
          { size: 12, color: { argb: '000000' } },
          null,
          { horizontal: 'left', vertical: 'middle', wrapText: true },
          {
            top: { style: 'thin', color: { argb: 'C7C7C7' } },
            left: { style: 'thin', color: { argb: 'C7C7C7' } },
            bottom: { style: 'thin', color: { argb: 'C7C7C7' } },
            right: { style: 'thin', color: { argb: 'C7C7C7' } }
          }
        );
      });

      addRowsHeight(homeSheet, studentListStartRow, studentListEndRow, 25);

      // Disclaimer message
      homeSheet.mergeCells('H8:H9');
      createCell(
        homeSheet,
        'H8',
        {
          richText: [
            {
              text: getGenderFormatMessage('educatorReportExcel_homeSheet_disclaimerMessageTitle'),
              font: { size: 12, bold: true, color: { argb: 'FF0099' } },
              alignment: { horizontal: 'right', vertical: 'middle', wrapText: true }
            },
            {
              text: `\n${getGenderFormatMessage('educatorReportExcel_homeSheet_disclaimerMessageText')}`,
              font: { size: 12, color: { argb: 'FF0099' } },
              alignment: { horizontal: 'right', vertical: 'middle', wrapText: true }
            }
          ]
        },
        null,
        null,
        { horizontal: 'right', vertical: 'middle', wrapText: true },
        {}
      );
      homeSheet.getRow(9).height = 50;

      //----------------------------------------------------------------
      // Students sheet
      for (const student of group.students) {
        const studentSheet = workbook.addWorksheet(`${student.lastName} ${student.firstName}`);
        studentSheet.views = [{ showGridLines: false, rightToLeft: true }];

        // Link back to home page
        studentSheet.mergeCells('J1:K1');
        createCell(
          studentSheet,
          'J1',
          {
            text: getGenderFormatMessage('educatorReportExcel_studentSheet_BackToHomePage'),
            hyperlink: `#'${getGenderFormatMessage('educatorReport_excel_homeSheetTabTitle')}'!A1`
          },
          { size: 12, bold: true, underline: true, color: { argb: 'A02B93' } },
          null,
          { horizontal: 'center', vertical: 'middle' },
          {}
        );
        studentSheet.getRow(1).height = 30;

        // Display student name and date
        studentSheet.mergeCells('A2');
        createCell(
          studentSheet,
          'A2',
          getGenderFormatMessage('educatorReportExcel_studentSheet_student'),
          { size: 16, bold: true, color: { argb: '5750FA' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {}
        );

        studentSheet.mergeCells('B2:C2');
        createCell(
          studentSheet,
          'B2',
          `${student.lastName} ${student.firstName}`,
          { size: 16, bold: true, color: { argb: '5750FA' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {}
        );

        studentSheet.mergeCells('I2:K2');
        createCell(
          studentSheet,
          'I2',
          `${getGenderFormatMessage('educatorReportExcel_homeSheet_reportDataFromDate')} ${minDate} ${getGenderFormatMessage(
            'educatorReportExcel_homeSheet_reportDataToDate'
          )} ${maxDate}`,
          { size: 14, bold: true, color: { argb: '000000' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {}
        );

        // Display student ID
        studentSheet.mergeCells('A3');
        createCell(
          studentSheet,
          'A3',
          getGenderFormatMessage('educatorReportExcel_studentSheet_Id'),
          { size: 14, color: { argb: '000000' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {}
        );

        studentSheet.mergeCells('B3:C3');
        createCell(studentSheet, 'B3', student.idNumber, { size: 14, color: { argb: '000000' } }, null, { horizontal: 'right', vertical: 'middle', wrapText: true }, {});

        // Display group name
        studentSheet.mergeCells('A4');
        createCell(
          studentSheet,
          'A4',
          getGenderFormatMessage('educatorReportExcel_studentSheet_group'),
          { size: 14, color: { argb: '000000' } },
          null,
          { horizontal: 'right', vertical: 'middle', wrapText: true },
          {}
        );

        studentSheet.mergeCells('B4:C4');
        createCell(studentSheet, 'B4', group.groupName, { size: 14, color: { argb: '000000' } }, null, { horizontal: 'right', vertical: 'middle', wrapText: true }, {});

        // Set fill color for rows 2, 3, and 4
        for (let row = 2; row <= 4; row++) {
          for (let col = 1; col <= 11; col++) {
            studentSheet.getCell(row, col).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2F2F2' } };
          }
        }

        // Define column configurations
        const columnConfigs = [
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader1'), width: 20, bgColor: 'D9D9D9', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader2'), width: 20, bgColor: 'D9D9D9', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader3'), width: 40, bgColor: 'BCE6F6', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader4'), width: 20, bgColor: 'BCE6F6', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader5'), width: 15, bgColor: 'BCE6F6', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader6'), width: 10, bgColor: 'BCE6F6', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader7'), width: 10, bgColor: 'BCE6F6', fontColor: '000000' },
          // { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader8'), width: 15, bgColor: 'BCE6F6', fontColor: '000000' }, // Number of answerd  questions
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader9'), width: 20, bgColor: 'BCE6F6', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader10'), width: 30, bgColor: 'BCE6F6', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader11'), width: 30, bgColor: 'D9D9D9', fontColor: '000000' },
          { header: getGenderFormatMessage('educatorReportExcel_studentSheet_tasksTableHeader12'), width: 15, bgColor: 'D9D9D9', fontColor: '000000' }
        ];

        studentSheet.columns = columnConfigs.map(({ width }) => ({ width }));

        // Initialize rows array
        const rows = [];

        student.tasks.forEach(task => {
          const row = [
            task.taskDisciplineNames !== '' && task.taskDisciplineNames.length > 0 ? task.taskDisciplineNames.join(', ') : '-',
            task.taskTeacherName ?? '-',
            /*task.url ? { text: task.taskName, hyperlink: task.url, tooltip: task.url } :*/ task.taskName,
            `[${task.taskSourceType}] ${task.taskSourceName}`,
            task.taskType,
            task.taskStart,
            task.taskEnd ?? '-',
            // `${task.taskNumOfAnsweredQuestions}/${task.taskNumOfQuestionsInLo}`,
            task.taskScore === -1 ? '-' : task.taskScore ?? '-',
            task.taskAverageScore ?? '-',
            `${task.taskNumOfSubmittedStudents}/${task.taskNumOfStudents}`,
            task.taskStudentStatusName
          ];
          rows.push(row);
        });

        // Add the table to the sheet
        studentSheet.addTable({
          name: `StudentTasksTable_${student.idNumber}`,
          ref: 'A9',
          headerRow: true,
          totalsRow: false,
          columns: columnConfigs.map(({ header }) => ({ name: header, filterButton: true })),
          rows: rows
        });

        // Apply header styling
        const headerRow = studentSheet.getRow(9);
        headerRow.height = 30;
        headerRow.eachCell((cell, colNumber) => {
          const { bgColor, fontColor } = columnConfigs[colNumber - 1]; // Map column configs
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor } };
          cell.font = { bold: true, color: { argb: fontColor } };
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          cell.border = {
            top: { style: 'thin', color: { argb: 'C7C7C7' } },
            left: { style: 'thin', color: { argb: 'C7C7C7' } },
            bottom: { style: 'thin', color: { argb: 'C7C7C7' } },
            right: { style: 'thin', color: { argb: 'C7C7C7' } }
          };
        });

        // Style each cell in the row based on column configuration
        studentSheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          // Skip the header row
          if (rowNumber > 9) {
            row.height = 25;
            row.eachCell((cell, colNumber) => {
              // Styling for score columns
              if (colNumber === 8 || colNumber === 9) {
                const score = row.getCell(colNumber).value;
                if (typeof score === 'number') {
                  cell.font = { color: { argb: 'FFFFFF' } };
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                      argb: score < 55 ? 'd6434d' : score < 70 ? 'ec9f41' : score < 85 ? '02c971' : '01944a' // Red, Orange, Light Green, Green
                    }
                  };
                }
              }

              cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
              cell.border = {
                top: { style: 'thin', color: { argb: 'C7C7C7' } },
                left: { style: 'thin', color: { argb: 'C7C7C7' } },
                bottom: { style: 'thin', color: { argb: 'C7C7C7' } },
                right: { style: 'thin', color: { argb: 'C7C7C7' } }
              };
              // Apply middle top border to the first row only
              if (rowNumber === 10) {
                cell.border.top = { style: 'medium', color: { argb: 'C7C7C7' } };
              }
            });
          }
        });
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const xlsxPopulateWorkbook = await XlsxPopulate.fromDataAsync(buffer);
      const protectedWorkbook = await xlsxPopulateWorkbook.outputAsync({ password: password });
      const blob = new Blob([protectedWorkbook], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      const fileName = `${getGenderFormatMessage('educatorReportExcel_excelName')}_${group.groupName}_${currentDate}.xlsx`;
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  return { generateExcelReport };
};
