import { useEffect, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { systemLanguages } from '../config';
import { useDispatch } from 'react-redux';
import { initDimensions } from '../state/dimensions';
import { initSectors, initSectorsByDisciplines } from '../state/sectors';
import { callWithCache } from '../utils/callWithCache';

const initialState = {
  isLoading: true,
  error: null
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'set_loading':
      return { ...state, isLoading: payload, error: null };
    case 'init_data':
      return {
        ...payload,
        error: null
      };
    case 'set_error':
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

const getLabels = languages => callWithCache(window.cet.microservices.nairobiapi.label.getLabels, 'getLabels', { languages });

const getAllDimensionsByLanguage = lang => callWithCache(window.cet.microservices.contentapi.content.getAllDimensionsByLanguage, 'getAllDimensionsByLanguage', { lang });

const getAllDependencies = () => callWithCache(window.cet.microservices.contentapi.content.getAllDependencies, 'getAllDependencies');

const getSectorsByDisciplines = () => callWithCache(window.cet.microservices.contentapi.content.getSectorsByDisciplines, 'getSectorsByDisciplines', {});

export const useInitApp = () => {
  const { language } = useParams();
  const history = useHistory();
  const [{ isLoading, error, i18nConstants, user, dimensions }, dispatch] = useReducer(reducer, initialState);
  const dispatchToStore = useDispatch();
  const isInTaskManagerIframe = window.location.pathname?.toLowerCase()?.includes('task-manager') || window.location.pathname?.toLowerCase()?.includes('environment-manager');

  useEffect(() => {
    if (!language || !systemLanguages.includes(language)) {
      history.push('/he');
    } else {
      const init = async () => {
        const rootScroll = document.getElementById('rootScroll');
        const root = document.getElementById('root');
        if (root) {
          rootScroll.style.direction = language !== 'en' ? 'ltr' : 'rtl';
          root.style.direction = language !== 'en' ? 'rtl' : 'ltr';
          root.dataset.direction = root.style.direction;
        } else {
          throw new Error('No root element! Check where you render your application!');
        }

        if (isInTaskManagerIframe) {
          dispatchToStore(initDimensions({ dimensions: {}, dependencies: {} }));
          dispatch({
            type: 'init_data',
            payload: {
              i18nConstants: { he: {} }
            }
          });
          dispatch({ type: 'set_loading', payload: false });
        } else {
          dispatch({ type: 'set_loading', payload: true });
          try {
            Promise.all([getLabels(language), getAllDimensionsByLanguage(language), getAllDependencies(), getSectorsByDisciplines()])
              .then(([i18nConstants, dimensions, dependencies, sectorsByDisciplines]) => {
                dispatchToStore(initDimensions({ dimensions, dependencies }));
                dispatchToStore(initSectors(language));
                dispatchToStore(initSectorsByDisciplines(sectorsByDisciplines, language));
                dispatch({
                  type: 'init_data',
                  payload: {
                    i18nConstants
                  }
                });
              })
              .catch(error => {
                dispatch({ type: 'set_error', payload: error });
              });
          } catch (error) {
            dispatch({ type: 'set_error', payload: error });
          }
        }
      };

      init();
    }
  }, [language, history, isInTaskManagerIframe, dispatchToStore]);

  useEffect(() => {
    if (error || (user && i18nConstants && dimensions)) {
      dispatch({ type: 'set_loading', payload: false });
    }
  }, [user, i18nConstants, dimensions, error]);

  return { isLoading, error, language, i18nConstants, user, dimensions };
};
